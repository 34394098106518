<template>
  <div :style="{height: winHeight + 'px'}">
    <div v-if="hasPermission" class="iframe-container">
      <iframe src="https://api.myonewow.com/ziomyun/tprank.php" />
    </div>
    <div v-else class="no-permission">
      If your account TP value is 0, you will not be able to view Scoreboards data.
    </div>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'; // 导入 axios 实例

export default {
  data() {
    return {
      winHeight: 200,
      hasPermission: false,
    };
  },
  async mounted() {
    const wh = document.body.clientHeight;
    this.winHeight = wh - 150;
    await this.checkUserPermission();
  },
  methods: {
    async checkUserPermission() {
      try {
        const response = await axiosIns({
          url: 'user/userinfo',
          method: 'get',
        });
        const all_tp = response.data.all_tp;
        this.hasPermission = all_tp > 0;
      } catch (error) {
        console.error('请求失败:', error);
      }
    },
  },
};
</script>

<style scoped>
.iframe-container {
  position: relative;
  height: 100%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: none;
}

.no-permission {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 16px;
}
</style>
